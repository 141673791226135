<template>
    <title-bar :title="$t('navigations.station')"/>
    <resource-listing :resource="$Stations" base-url="stations" add-button="messages.stationAdd"
                      delete-message="messages.stationDeleted"/>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceListing from '../components/ResourceListing.vue'

export default {
    components: {
        TitleBar,
        ResourceListing
    },
}
</script>
